import React, { Component } from "react";
import SideBar from "../common/sideBar";
import UserProfile from "../common/userProfile";
import Pagination from "../common/Pagination";
import ModalPopup from "../common/modal";
import { connect } from "react-redux";
import OrgGrid from "./orgGrid";
import AdministratorEmpty from "../administrator/adminEmpty";
import SortingIcon from "../common/sortingIcon";
import HelmetComponent from "../common/helmetComponent";
import {
  deletedAdministrator,
  updateAdministrator,
} from "../../store/administrator";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import { deleted, activated, deactivated, failed } from "../common/misc";
import SortingIconUp from "../common/sortingIconUp";
import {
  deleteUser,
  getUsers,
  loadAllUsers,
  loaduserAnalytics,
} from "../../store/users";
import { markAsCompleted } from "../../store/misc";

import AddOrg from "./addOrg";
import Search from "./search";
import { debounce } from "lodash";
import axios from "axios";

class OrgTable extends Component {
  state = {
    loading: false,
    trainingAttendedId: "",
    trainingsAttendedShow: false,
    userShow: false,
    isShow: false,
    modal: false,
    active: false,
    id: "",
    admin_id: "",
    status: "",
    msg: "",
    data: "",
    totalRecords: "",
    totalPages: "",
    pageLimit: "",
    currentPage: "",
    editShow: false,
    delete_id: "",
    changePass: "false",
    resPass: "false",
    change_id: "",
    asside: false,
    asside2: false,
    asside3: false,
    sort: "attemptedTrainingCount",
    order: "desc",
    userType: "admin",
    keyword: "",
    markAgent: false,
    agentId: "",
  };

  handleSearch = debounce(() => {
    this.props.loadAllUsers({
      page: "1",
      sort: this.state.sort,
      order: this.state.order,
      keyword: this.state.keyword,
    });
  }, 300);

  onSearch = (value) => {
    this.setState(
      {
        keyword: value,
      },
      () => this.handleSearch()
    );
  };

  reset = () => {
    this.setState(
      {
        keyword: "",
      },
      () => this.handleSearch()
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.sort !== prevState.sort ||
      this.state.order !== prevState.order
    ) {
      this.props.loadAllUsers({
        page: this.state.currentPage,
        sort: this.state.sort,
        order: this.state.order,
      });
    }

    if (
      this.state.trainingsAttendedShow !== prevState.trainingsAttendedShow &&
      this.state.trainingsAttendedShow
    ) {
      this.setState({ loading: true });
      this.props.loaduserAnalytics(
        {
          page: "1",
          sort: "createdAt",
          order: "desc",
          user: this.state.trainingAttendedId,
        },
        () => this.setState({ loading: false })
      );
    }
  };

  sortByName = () => {
    if (this.state.order === "desc")
      this.setState({
        sort: "fullName",
        order: "asc",
      });
    else {
      this.setState({
        sort: "fullName",
        order: "desc",
      });
    }
  };

  sortByEmail = () => {
    if (this.state.order === "desc")
      this.setState({
        sort: "email",
        order: "asc",
      });
    else {
      this.setState({
        sort: "email",
        order: "desc",
      });
    }
  };
  sortByCreatedAt = () => {
    if (this.state.order === "desc")
      this.setState({
        sort: "createdAt",
        order: "asc",
      });
    else {
      this.setState({
        sort: "createdAt",
        order: "desc",
      });
    }
  };

  sortByTrainingAttended = () => {
    if (this.state.order === "desc")
      this.setState({
        sort: "attemptedTrainingCount",
        order: "asc",
      });
    else {
      this.setState({
        sort: "attemptedTrainingCount",
        order: "desc",
      });
    }
  };

  toggleAddUser = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };

  toggleShowUser = () => {
    this.setState({
      userShow: !this.state.userShow,
    });
  };
  toggleAcitve = (id, status, msg) => {
    this.setState({
      active: !this.state.active,
      admin_id: id,
      status: status,
      msg: msg,
    });
  };
  toggleEditUser = (data, id) => {
    this.setState({
      editShow: !this.state.editShow,
      data: data,
      id: id,
    });
  };
  toggleChangePassword = (id) => {
    this.setState({
      changePass: !this.state.changePass,
      change_id: id,
    });
  };
  toggleResPassword = (id) => {
    this.setState({
      resPass: !this.state.resPass,
      change_id: id,
    });
  };
  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      delete_id: id,
    });
  };

  toggleTrainingsAttended = (id) => {
    this.setState({
      trainingsAttendedShow: !this.state.trainingsAttendedShow,
      trainingAttendedId: id,
    });
  };

  UpdateAdmin = (id) => {
    const formData = {
      is_active: this.state.status,
    };
    this.props.updateAdministrator(id, formData, this.callbackActive);
  };
  callbackActive = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllUsers({
        page: this.state.currentPage,
        sort: this.state.sort,
        order: this.state.order,
      });
      this.setState({
        active: !this.state.active,
      });
      if (this.state.status === true) {
        //toast(<AlertSuccess message={activated} />);
      } else {
        toast(<AlertSuccess message={deactivated} />);
      }
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  deletedUser = (id) => {
    this.props.deleteUser(id, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllUsers({
        page: this.state.currentPage,
        sort: this.state.sort,
        order: this.state.order,
      });
      this.setState({
        modal: !this.state.modal,
      });
      // toast(<AlertSuccess message={deleted} />);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };
  componentDidMount = () => {
    if (this.state.currentPage) {
      this.props.loadAllUsers({
        page: this.state.currentPage,
        sort: this.state.sort,
        order: this.state.order,
      });
    }
  };

  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (data.page !== this.state.currentPage) {
      this.props.loadAllUsers({
        page: data.page,
        sort: this.state.sort,
        order: this.state.order,
      });
    }
  };

  toggleMarkAgent = (id) => {
    this.setState({ markAgent: !this.state.markAgent, agentId: id });
  };

  handleMarkAgent = (id) => {
    this.props.markAsCompleted(id, (res) => {
      if (res && res.status === 200) {
        this.props.loadAllUsers({
          page: this.state.currentPage,
          sort: this.state.sort,
          order: this.state.order,
        });
        this.setState({
          markAgent: !this.state.markAgent,
        });
        // toast(<AlertSuccess message={deleted} />);
      } else {
        toast(
          <AlertError
            message={
              res.data.message ? res.data.message : "Something went wrong"
            }
          />
        );
      }
    });
  };
  render() {
    const { users, loading, userAnalytics } = this.props.users;
    const { userType } = JSON.parse(localStorage.getItem("admin_details"));
    const { total_record, current_page, record_per_page, total_page } =
      this.props.pagination;
    return (
      <div
        className={
          this.state.asside === true ||
          this.state.asside2 ||
          this.state.asside3 === true
            ? "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled"
            : "header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled  aside-minimize"
        }
      >
        <div className="d-flex flex-column flex-root">
          <HelmetComponent title="Administrators Management" />
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar
              toggleAsside={this.toggleAsside}
              toggleAsside3={this.toggleAsside3}
              userShow={this.state.userShow}
              toggleShowUser={this.toggleShowUser}
              asside={this.state.asside}
              asside3={this.state.asside3}
              asside2={this.state.asside2}
              toggleAsside2={this.toggleAsside2}
            />
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <div className="content d-flex flex-column flex-column-fluid">
                <div className="subheader py-2 py-lg-4 subheader-transparent">
                  <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-2">
                      <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        Users
                      </h5>
                      <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
                      <div className="d-flex align-items-center">
                        <span className="text-dark-50 font-weight-bold">
                          {total_record > 0
                            ? "Total :" + " " + total_record + " " + "records"
                            : "Total : 0 record"}
                        </span>
                        <Search
                          keyword={this.state.keyword}
                          onSearch={this.onSearch}
                          reset={this.reset}
                        />
                      </div>
                    </div>

                    <div className="d-flex align-items-center">
                      <a
                        href="#/"
                        className="btn btn-primary font-weight-bold btn-sm font-size-base ml-2"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleAddUser();
                        }}
                        data-toggle="tooltip"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                      >
                        Add User
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column-fluid">
                  <div className="container-fluid">
                    <div className="card card-custom">
                      <div className="card-body">
                        <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
                          <table
                            className="datatable-table"
                            style={{ display: "block" }}
                          >
                            <thead className="datatable-head">
                              <tr
                                className="datatable-row"
                                style={{ left: "0px" }}
                              >
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "150px" }}>
                                    Name
                                    {this.state.sort === "fullName" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon sorting={this.sortByName} />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByName}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "100px" }}>Mobile</span>
                                </th>

                                {/* <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "200px" }}>
                                    Email
                                    {this.state.sort === "email" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon sorting={this.sortByEmail} />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByEmail}
                                      />
                                    )}
                                  </span>
                                </th> */}
                                {/* <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "200px" }}>
                                    Client App ID
                                  </span>
                                </th> */}

                                {/* <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "130px" }}>
                                    Date of birth
                                  </span>
                                </th> */}
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "120px" }}>
                                    Trainings Attended
                                    {this.state.sort ===
                                      "attemptedTrainingCount" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByTrainingAttended}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByTrainingAttended}
                                      />
                                    )}
                                  </span>
                                </th>

                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "120px" }}>
                                    Created By
                                  </span>
                                </th>

                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "120px" }}>
                                    Created At
                                    {this.state.sort === "createdAt" &&
                                    this.state.order === "asc" ? (
                                      <SortingIcon
                                        sorting={this.sortByCreatedAt}
                                      />
                                    ) : (
                                      <SortingIconUp
                                        sorting={this.sortByCreatedAt}
                                      />
                                    )}
                                  </span>
                                </th>
                                <th className="datatable-cell datatable-cell-sort">
                                  <span style={{ width: "170px" }}>
                                    Actions
                                  </span>
                                </th>

                                {/* {userType === "superadmin" && (
                                  <th className="datatable-cell datatable-cell-sort">
                                    <span style={{ width: "145px" }}>
                                      Action
                                    </span>
                                  </th>
                                )} */}
                              </tr>
                            </thead>
                            {loading === false && users.length === 0 ? (
                              <AdministratorEmpty />
                            ) : (
                              <OrgGrid
                                changePass={this.state.changePass}
                                toggleChangePassword={this.toggleChangePassword}
                                change_id={this.state.change_id}
                                delete_id={this.state.delete_id}
                                initialPage={this.state.currentPage}
                                editShow={this.state.editShow}
                                toggleEditUser={this.toggleEditUser}
                                data={this.state.data}
                                msg={this.state.msg}
                                status_id={this.state.admin_id}
                                Update={this.UpdateAdmin}
                                modalActive={this.state.active}
                                toggleAcitve={this.toggleAcitve}
                                users={users}
                                modal={this.state.modal}
                                toggle={this.toggle}
                                id={this.state.id}
                                deleted={this.deletedUser}
                                resPass={this.state.resPass}
                                toggleResPassword={this.toggleResPassword}
                                toggleTrainingsAttended={
                                  this.toggleTrainingsAttended
                                }
                                trainingAttendedId={
                                  this.state.trainingAttendedId
                                }
                                trainingsAttendedShow={
                                  this.state.trainingsAttendedShow
                                }
                                userAnalytics={userAnalytics}
                                loading={this.state.loading}
                                handleMarkAgent={this.handleMarkAgent}
                                toggleMarkAgent={this.toggleMarkAgent}
                                markAgent={this.state.markAgent}
                                agentId={this.state.agentId}
                              />
                            )}
                          </table>
                          <Pagination
                            totalRecords={total_record}
                            total_page={total_page}
                            pageLimit={record_per_page}
                            initialPage={current_page}
                            pagesToShow={5}
                            onChangePage={this.onChangePage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UserProfile
            userShow={this.state.userShow}
            toggleShowUser={this.toggleShowUser}
          />
          <AddOrg
            initialPage={this.state.currentPage}
            isShow={this.state.isShow}
            toggleAddUser={this.toggleAddUser}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  users: getUsers(state),
  pagination: getUsers(state).pagination,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllUsers: (params) => dispatch(loadAllUsers(params)),
  deleteUser: (id, callback) => dispatch(deleteUser(id, callback)),
  updateAdministrator: (id, data, callbackActive) =>
    dispatch(updateAdministrator(id, data, callbackActive)),
  loaduserAnalytics: (params, callback) =>
    dispatch(loaduserAnalytics(params, callback)),
  markAsCompleted: (id, callback) => dispatch(markAsCompleted(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgTable);
