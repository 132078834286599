import React from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";

import _ from "lodash";
import {
  addQuestion,
  getAssessment,
  loadDetailAssessment,
  updateQuestion,
  uploadQuestionImage,
} from "../../store/assessment";

const initialState = {
  photoStatus: false,
  type: [
    { id: 1, name: "1", label: "Multiple Choice with one Answer" },
    {
      id: 2,
      name: "2",
      label: "Multiple Choice with more than one Answers",
    },
    { id: 3, name: "3", label: "Information (Image, title, text)" },
    { id: 4, name: "4", label: "True/False" },
  ],
  // subType: [
  //   { id: 1, name: "readOnly", label: "Read only" },
  //   {
  //     id: 2,
  //     name: "text",
  //     label: "Text",
  //   },
  // ],

  data: {
    question: "",
    description: "",
    response_type: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: [],
    displayOrder: "",
  },
  // readOnly: "",
  disable: false,
  images: {},
  errors: {},
  orgData: {},
};

class AddQuestion extends Form {
  state = initialState;
  schema = {
    question: Joi.string()
      .required()
      .label("Write Your Question")
      .error(() => {
        return { message: "Question Title is required" };
      }),
    displayOrder: Joi.number()
      .required()
      .label("Display Order")
      .error(() => {
        return { message: "Display order is required" };
      }),
    description: Joi.string()
      .required()
      .label("Description")
      .error(() => {
        return { message: "Question text is required" };
      }),
    response_type: Joi.string()
      .required()
      .label("Response Type")
      .error(() => {
        return { message: "Response Type is required" };
      }),
    option1: Joi.string()
      .required()
      .label("Option 1")
      .error(() => {
        return { message: "Option 1 is required" };
      }),
    option2: Joi.string()
      .required()
      .label("Option 2")
      .error(() => {
        return { message: "Option 2 is required" };
      }),
    option3: Joi.string().allow(""),
    option4: Joi.string().allow(""),
    answer: Joi.array()
      .min(1)
      .max(4)
      .required()
      .label("Answer")
      .error(() => {
        return { message: "Answer is required" };
      }),
  };
  doSubmit = () => {
    const data = this.state.data;
    const errors = this.state.errors;
    const optionsArray = [];

    if (data.response_type === "1" || data.response_type === "2") {
      optionsArray.push(data.option1);
      optionsArray.push(data.option2);
      if (data.option3) optionsArray.push(data.option3);
      if (data.option4) optionsArray.push(data.option4);
    } else if (data.response_type === "4") {
      optionsArray.push("true");
      optionsArray.push("false");
    }

    var formData;
    formData = {
      section: this.props.sectionId,
      answers: data.answer,
      text: data.description,
      title: data.question,
      questionType: data.response_type,
      options: optionsArray,
      image: this.state.images,
      displayOrder: data.displayOrder,
    };
    // if (data.response_type === "3") {
    //   formData.readOnly = this.state.readOnly === "readOnly" ? true : false;
    // } else {
    //   formData.readOnly = null;
    // }
    if (this.props.editingQuestion) formData.question = this.props.questionId;
    if (this.props.editingQuestion) {
      this.props.updateQuestion(this.props.id, formData, this.callback);
    } else {
      this.props.addQuestion(this.props.id, formData, this.callback);
    }
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.data.response_type === "3" &&
      this.state.data.response_type !== prevState.data.response_type
    ) {
      const data = this.state.data;
      data.answer = [];
      this.setState({ data });
      this.schema.option1 = Joi.string().allow("");
      this.schema.option2 = Joi.string().allow("");
      this.schema.answer = Joi.array().allow([]);
    }

    if (this.state.data.response_type === "3") {
      this.schema.option1 = Joi.string().allow("");
      this.schema.option2 = Joi.string().allow("");
      this.schema.answer = Joi.array().allow([]);
    }

    if (
      this.state.data.response_type === "2" &&
      this.state.data.response_type !== prevState.data.response_type
    ) {
      const data = this.state.data;
      data.answer = [];
      this.setState({ data });
      this.schema.option1 = Joi.string()
        .required()
        .label("Option 1")
        .error(() => {
          return { message: "Option 1 is required" };
        });
      this.schema.option2 = Joi.string()
        .required()
        .label("Option 2")
        .error(() => {
          return { message: "Option 2 is required" };
        });
      this.schema.answer = Joi.array()
        .min(2)
        .required()
        .label("Answer")
        .error(() => {
          return { message: "Minimun two answer is required" };
        });
    }
    if (
      this.state.data.response_type === "1" &&
      this.state.data.response_type !== prevState.data.response_type
    ) {
      const data = this.state.data;
      data.answer = [];
      this.setState({ data });
      this.schema.option1 = Joi.string()
        .required()
        .label("Option 1")
        .error(() => {
          return { message: "Option 1 is required" };
        });
      // this.schema.option2 = Joi.string()
      //   .required()
      //   .label("Option 2")
      //   .error(() => {
      //     return { message: "Option 2 is required" };
      //   });
      this.schema.answer = Joi.array()
        .min(1)
        .required()
        .label("Answer")
        .error(() => {
          return { message: "Answer is required" };
        });
    }
    if (
      this.state.data.response_type === "4" &&
      this.state.data.response_type !== prevState.data.response_type
    ) {
      const data = this.state.data;
      data.answer = [];
      this.setState({ data });
      this.schema.option1 = Joi.string().allow("");
      this.schema.option2 = Joi.string().allow("");
      this.schema.answer = Joi.array()
        .min(1)
        .required()
        .label("Answer")
        .error(() => {
          return { message: "Answer is required" };
        });
    }
    if (this.state.data.response_type === "4") {
      this.schema.option1 = Joi.string().allow("");
      this.schema.option2 = Joi.string().allow("");
      this.schema.answer = Joi.array()
        .min(1)
        .required()
        .label("Answer")
        .error(() => {
          return { message: "Answer is required" };
        });
    }
    if (
      this.props.editingQuestion &&
      this.props.editDataQuestion !== null &&
      this.props.editDataQuestion !== prevProps.editDataQuestion
    ) {
      const { data } = this.state;
      let answer = [];
      data.question = this.props.editDataQuestion.title;
      data.description = this.props.editDataQuestion.text;
      data.response_type = this.props.editDataQuestion.questionType;
      data.displayOrder = this.props.editDataQuestion.displayOrder;
      this.props.editDataQuestion.answers.map((ans) => answer.push(ans));
      data.answer = answer;
      if (this.props.editDataQuestion.questionType === "1") {
        data.option1 =
          this.props.editDataQuestion.options &&
          this.props.editDataQuestion.options[0];
        data.option2 =
          this.props.editDataQuestion.options &&
          this.props.editDataQuestion.options[1];
        if (
          this.props.editDataQuestion.options &&
          this.props.editDataQuestion.options.length > 2 &&
          this.props.editDataQuestion.options.length < 4
        ) {
          data.option3 = this.props.editDataQuestion.options[2];
        }
        if (
          this.props.editDataQuestion.options &&
          this.props.editDataQuestion.options.length > 2 &&
          this.props.editDataQuestion.options.length <= 4
        ) {
          data.option3 = this.props.editDataQuestion.options[2];
          data.option4 = this.props.editDataQuestion.options[3];
        }
      }
      if (this.props.editDataQuestion.questionType === "2") {
        data.option1 =
          this.props.editDataQuestion.options &&
          this.props.editDataQuestion.options[0];
        data.option2 =
          this.props.editDataQuestion.options &&
          this.props.editDataQuestion.options[1];
        if (
          this.props.editDataQuestion.options &&
          this.props.editDataQuestion.options.length > 2 &&
          this.props.editDataQuestion.options.length < 4
        ) {
          data.option3 = this.props.editDataQuestion.options[2];
        }
        if (
          this.props.editDataQuestion.options &&
          this.props.editDataQuestion.options.length > 2 &&
          this.props.editDataQuestion.options.length <= 4
        ) {
          data.option3 = this.props.editDataQuestion.options[2];
          data.option4 = this.props.editDataQuestion.options[3];
        }
      }
      this.setState({
        data,
        images: this.props.editDataQuestion.image,
        // readOnly:
        //   this.props.editDataQuestion.questionType === "3"
        //     ? this.props.editDataQuestion.readOnly == true
        //       ? "readOnly"
        //       : "text"
        //     : "",
      });
    }
    if (
      this.state.data.option1 !== prevState.data.option1 &&
      prevState.data.answer.includes(prevState.data.option1)
    ) {
      const data = this.state.data;

      const prev = prevState.data.answer.indexOf(prevState.data.option1);
      data.answer[prev] = this.state.data.option1;
      this.setState({ data });
    }
    if (
      this.state.data.option2 !== prevState.data.option2 &&
      prevState.data.answer.includes(prevState.data.option2)
    ) {
      const data = this.state.data;

      const prev = prevState.data.answer.indexOf(prevState.data.option2);
      data.answer[prev] = this.state.data.option2;
      this.setState({ data });
    }
    if (
      this.state.data.option3 !== prevState.data.option3 &&
      prevState.data.answer.includes(prevState.data.option3)
    ) {
      const data = this.state.data;

      const prev = prevState.data.answer.indexOf(prevState.data.option3);
      data.answer[prev] = this.state.data.option3;
      this.setState({ data });
    }
    if (
      this.state.data.option4 !== prevState.data.option4 &&
      prevState.data.answer.includes(prevState.data.option4)
    ) {
      const data = this.state.data;

      const prev = prevState.data.answer.indexOf(prevState.data.option4);
      data.answer[prev] = this.state.data.option4;
      this.setState({ data });
    }
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadDetailAssessment(this.props.id);
      this.toggleClose();
      // toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={res.data.message} />);
      this.setState(initialState);
    }
  };
  editCallback = (res) => {
    if (res && res.status === 200) {
      this.props.loadDetailAssessment(this.props.id);
      this.toggleClose();
      // toast(<AlertSuccess message={informationSaved} />);
    } else {
      toast(<AlertError message={res.data.message} />);
      this.setState(initialState);
    }
  };

  handleOnChange(e) {
    const data = { ...this.state.data };
    data.response_type = e.target.name;
    this.setState({ data });
  }
  toggleClose = () => {
    this.setState(
      {
        photoStatus: false,
        type: [
          { id: 1, name: "1", label: "Multiple Choice with one Answer" },
          {
            id: 2,
            name: "2",
            label: "Multiple Choice with more than one Answers",
          },
          { id: 3, name: "3", label: "Information (Image, title, text)" },
          { id: 4, name: "4", label: "True/False" },
        ],

        data: {
          question: "",
          description: "",
          response_type: "",
          option1: "",
          option2: "",
          option3: "",
          option4: "",
          answer: [],
          displayOrder: "",
        },
        disable: false,
        // readOnly: "",
        images: {},
        errors: {},
      },
      () => {
        this.props.setEditingQuestion(false);
        this.props.setEditingData(null);
        this.props.toggleAddQuestion();
      }
    );
  };

  uploadFile = async (e) => {
    if (e.target.files[0].size / 1024 / 1024 > 0.5) {
      toast(
        <AlertError message={"Image file size should not exceed 0.5 mb"} />
      );
    } else {
      this.setState({
        loading: true,
        browserLabel: "Uploading...",
        disable: true,
      });
      this.setState({ imageName: e.target.files[0].name });
      const file = e.target.files[0];
      const data = new FormData();
      data.append("image", file);

      this.props.uploadQuestionImage(data, (res) => {
        const errors = { ...this.state.errors };
        this.setState({ images: res.data.data });
        delete errors.images;
        this.setState({
          loading: false,
          errors,
          browserLabel: "Browse",
          disable: false,
        });
      });
    }
  };

  componentDidMount = () => {
    const data = JSON.parse(localStorage.getItem("organizationData"));
    this.setState({ orgData: data });
  };
  render() {
    const orgData = this.state.orgData;
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas3 offcanvas-left p-10 offcanvas-on "
              : "offcanvas offcanvas3 offcanvas-left p-10"
          }
          style={{ height: "100%", "overflow-y": "auto" }}
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">
              {this.props.editingQuestion ? "Edit Question" : "Add Question"}
            </h3>

            <a
              href="javascript:void (0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={(e) => {
                e.preventDefault();
                this.toggleClose();
              }}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("question", "Question Title")}
            {this.renderTextarea("description", "Question Text")}
            {this.renderInput("displayOrder", "Display order", "number")}
            <div className="form-group">
              <label
                className={this.state.errors.photo ? "errorColor" : ""}
                htmlFor="photograph2"
              >
                Question Image (Image file size should not exceed 0.5 mb)
              </label>
              <input
                accept="image/*"
                type="file"
                id="photograph2"
                className="form-control "
                onChange={this.uploadFile}
                disabled={this.state.disable}
              />

              {this.state.errors.image && (
                <div className="fv-plugins-message-container">
                  <div data-validator="notEmpty" className="fv-help-block">
                    {this.state.errors.image}
                  </div>
                </div>
              )}
            </div>

            {!_.isEmpty(this.state.images) && (
              <div
                className="mb-5"
                style={{ width: "50%", position: "relative" }}
              >
                <img
                  style={{ width: "100%", height: "50%", borderRadius: "5px" }}
                  className="img-fluid"
                  src={
                    !_.isEmpty(orgData) &&
                    orgData.mediaServerURL +this.state.images.original
                  }
                  alt=""
                />
                <a
                  style={{ position: "absolute", top: "-10px", right: "-10px" }}
                  href="javascript:void (0)"
                  className="btn btn-xs btn-icon btn-light btn-hover-primary"
                  onClick={() => {
                    this.setState({ images: {} });
                  }}
                >
                  <i className="ki ki-close icon-xs text-muted"></i>
                </a>
                {/* <div className="close-box">
                  <img src={close} alt="" />
                </div> */}
              </div>
            )}
            <div class="form-group">
              <label>Response Type</label>
              <div class="radio-list">
                {this.state.type.map((e, i) => (
                  <label class="radio" key={i}>
                    <input
                      type="radio"
                      onChange={(e) => this.handleOnChange(e)}
                      checked={this.state.data.response_type === e.name}
                      name={e.name}
                    />
                    <span></span>
                    {e.label}
                  </label>
                ))}
                {/* {this.state.data.response_type === "3" && (
                  <div class="radio-list mt-5">
                    {this.state.subType.map((e, i) => (
                      <label class="radio" key={e.name}>
                        <input
                          type="radio"
                          value={e.name}
                          onChange={(e) => {
                            const { value, checked } = e.target;
                            if (checked) {
                              this.setState({
                                readOnly: value,
                              });
                            }
                          }}
                          checked={this.state.readOnly === e.name}
                          name={e.name}
                        />
                        <span></span>
                        {e.label}
                      </label>
                    ))}
                  </div>
                )} */}
              </div>

              {this.state.errors.response_type && (
                <div className="fv-plugins-message-container">
                  <div data-validator="notEmpty" className="fv-help-block">
                    {this.state.errors.response_type}
                  </div>
                </div>
              )}
            </div>

            {this.state.data.response_type === "1" ? (
              <>
                <div className="form-group" style={{ marginBottom: "0.50rem" }}>
                  <div className="d-flex">
                    <div class="radio-list" style={{ position: "relative" }}>
                      <label>Answer</label>
                      <label class="radio" style={{ marginTop: "15px" }}>
                        <input
                          type="radio"
                          value={this.state.data.option1}
                          onChange={(e) => {
                            const { value, checked } = e.target;
                            if (checked) {
                              let answer = [];
                              const data = this.state.data;
                              answer.push(value);
                              data.answer = answer;
                              this.setState({ data });
                            } else {
                              const data = this.state.data;
                              data.answer = [];
                              this.setState({ data });
                            }
                          }}
                          checked={
                            this.state.data.option1 &&
                            this.state.data.answer &&
                            this.state.data.answer.length > 0 &&
                            this.state.data.answer.find(
                              (ans) => ans === this.state.data.option1
                            )
                              ? true
                              : false
                          }
                          name={this.state.data.option1}
                        />
                        <span></span>
                      </label>
                    </div>
                    <div style={{ width: "100%", marginLeft: "20px" }}>
                      {this.renderInput("option1", "Option 1")}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div class="radio-list" style={{ position: "relative" }}>
                      <label class="radio" style={{ marginTop: "35px" }}>
                        <input
                          type="radio"
                          value={this.state.data.option2}
                          onChange={(e) => {
                            const { value, checked } = e.target;
                            if (checked) {
                              let answer = [];
                              const data = this.state.data;
                              answer.push(value);
                              data.answer = answer;
                              this.setState({ data });
                            } else {
                              const data = this.state.data;
                              data.answer = [];
                              this.setState({ data });
                            }
                          }}
                          checked={
                            this.state.data.option2 &&
                            this.state.data.answer &&
                            this.state.data.answer.length > 0 &&
                            this.state.data.answer.find(
                              (ans) => ans === this.state.data.option2
                            )
                              ? true
                              : false
                          }
                          name={this.state.data.option2}
                        />
                        <span></span>
                      </label>
                    </div>
                    <div style={{ width: "100%", marginLeft: "36px" }}>
                      {this.renderInput("option2", "Option 2")}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div class="radio-list" style={{ position: "relative" }}>
                      <label class="radio" style={{ marginTop: "35px" }}>
                        <input
                          type="radio"
                          value={this.state.data.option3}
                          onChange={(e) => {
                            const { value, checked } = e.target;
                            if (checked) {
                              let answer = [];
                              const data = this.state.data;
                              answer.push(value);
                              data.answer = answer;
                              this.setState({ data });
                            } else {
                              const data = this.state.data;
                              data.answer = [];
                              this.setState({ data });
                            }
                          }}
                          checked={
                            this.state.data.option3 &&
                            this.state.data.answer &&
                            this.state.data.answer.length > 0 &&
                            this.state.data.answer.find(
                              (ans) => ans === this.state.data.option3
                            )
                              ? true
                              : false
                          }
                          name={this.state.data.option3}
                        />
                        <span></span>
                      </label>
                    </div>

                    <div style={{ width: "100%", marginLeft: "36px" }}>
                      {this.renderInput("option3", "Option 3")}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div class="radio-list" style={{ position: "relative" }}>
                      <label class="radio" style={{ marginTop: "35px" }}>
                        <input
                          type="radio"
                          value={this.state.data.option4}
                          onChange={(e) => {
                            const { value, checked } = e.target;
                            if (checked) {
                              let answer = [];
                              const data = this.state.data;
                              answer.push(value);
                              data.answer = answer;
                              this.setState({ data });
                            } else {
                              const data = this.state.data;
                              data.answer = [];
                              this.setState({ data });
                            }
                          }}
                          checked={
                            this.state.data.option4 &&
                            this.state.data.answer &&
                            this.state.data.answer.length > 0 &&
                            this.state.data.answer.find(
                              (ans) => ans === this.state.data.option4
                            )
                              ? true
                              : false
                          }
                          name={this.state.data.option4}
                        />
                        <span></span>
                      </label>
                    </div>
                    <div style={{ width: "100%", marginLeft: "36px" }}>
                      {this.renderInput("option4", "Option 4")}
                    </div>
                  </div>
                </div>
                {this.state.errors.answer && (
                  <div className="fv-plugins-message-container">
                    <div data-validator="notEmpty" className="fv-help-block">
                      {this.state.errors.answer}
                    </div>
                  </div>
                )}
              </>
            ) : this.state.data.response_type === "2" ? (
              <>
                <div className="form-group" style={{ marginBottom: "0.50rem" }}>
                  <div className="d-flex">
                    <div class="form-group">
                      <label>Answer</label>
                      <div class="checkbox-list" style={{ marginTop: "10px" }}>
                        <label class="checkbox">
                          <input
                            type="checkbox"
                            value={this.state.data.option1}
                            onChange={(e) => {
                              const { value, checked } = e.target;

                              if (checked) {
                                let answer = [];

                                const data = this.state.data;
                                answer.push(value);
                                data.answer = [...data.answer, ...answer];

                                this.setState({ data });
                              } else {
                                const data = this.state.data;
                                const a = this.state.data.answer.filter(
                                  (item) => item !== value
                                );
                                data.answer = [...a];
                                this.setState({ data });
                              }
                            }}
                            checked={
                              this.state.data.option1 &&
                              this.state.data.answer &&
                              this.state.data.answer.length > 0 &&
                              this.state.data.answer.find(
                                (ans) => ans === this.state.data.option1
                              )
                                ? true
                                : false
                            }
                          />
                          <span></span>
                          <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        </label>
                      </div>
                    </div>
                    <div style={{ width: "100%", marginLeft: "20px" }}>
                      {this.renderInput("option1", "Option 1")}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div class="form-group" style={{ marginTop: "35px" }}>
                      <div class="checkbox-list">
                        <label class="checkbox">
                          <input
                            type="checkbox"
                            value={this.state.data.option2}
                            onChange={(e) => {
                              const { value, checked } = e.target;
                              if (checked) {
                                let answer = [];
                                const data = this.state.data;
                                answer.push(value);
                                data.answer = [...data.answer, ...answer];
                                this.setState({ data });
                              } else {
                                const data = this.state.data;
                                const a = this.state.data.answer.filter(
                                  (item) => item !== value
                                );
                                data.answer = [...a];
                                this.setState({ data });
                              }
                            }}
                            checked={
                              this.state.data.option2 &&
                              this.state.data.answer &&
                              this.state.data.answer.length > 0 &&
                              this.state.data.answer.find(
                                (ans) => ans === this.state.data.option2
                              )
                                ? true
                                : false
                            }
                          />
                          <span></span>
                          <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        </label>
                      </div>
                    </div>
                    <div style={{ width: "100%", marginLeft: "20px" }}>
                      {this.renderInput("option2", "Option 2")}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div class="form-group" style={{ marginTop: "35px" }}>
                      <div class="checkbox-list">
                        <label class="checkbox">
                          <input
                            type="checkbox"
                            value={this.state.data.option3}
                            onChange={(e) => {
                              const { value, checked } = e.target;
                              if (checked) {
                                let answer = [];
                                const data = this.state.data;
                                answer.push(value);
                                data.answer = [...data.answer, ...answer];
                                this.setState({ data });
                              } else {
                                const data = this.state.data;
                                const a = this.state.data.answer.filter(
                                  (item) => item !== value
                                );
                                data.answer = [...a];
                                this.setState({ data });
                              }
                            }}
                            checked={
                              this.state.data.option3 &&
                              this.state.data.answer &&
                              this.state.data.answer.length > 0 &&
                              this.state.data.answer.find(
                                (ans) => ans === this.state.data.option3
                              )
                                ? true
                                : false
                            }
                          />
                          <span></span>
                          <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        </label>
                      </div>
                    </div>

                    <div style={{ width: "100%", marginLeft: "20px" }}>
                      {this.renderInput("option3", "Option 3")}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div class="form-group" style={{ marginTop: "35px" }}>
                      <div class="checkbox-list">
                        <label class="checkbox">
                          <input
                            type="checkbox"
                            value={this.state.data.option4}
                            onChange={(e) => {
                              const { value, checked } = e.target;
                              if (checked) {
                                let answer = [];
                                const data = this.state.data;
                                answer.push(value);
                                data.answer = [...data.answer, ...answer];
                                this.setState({ data });
                              } else {
                                const data = this.state.data;
                                const a = this.state.data.answer.filter(
                                  (item) => item !== value
                                );
                                data.answer = [...a];
                                this.setState({ data });
                              }
                            }}
                            checked={
                              this.state.data.option4 &&
                              this.state.data.answer &&
                              this.state.data.answer.length > 0 &&
                              this.state.data.answer.find(
                                (ans) => ans === this.state.data.option4
                              )
                                ? true
                                : false
                            }
                          />
                          <span></span>
                          <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        </label>
                      </div>
                    </div>
                    <div style={{ width: "100%", marginLeft: "20px" }}>
                      {this.renderInput("option4", "Option 4")}
                    </div>
                  </div>
                </div>
                {this.state.errors.answer && (
                  <div className="fv-plugins-message-container">
                    <div data-validator="notEmpty" className="fv-help-block">
                      {this.state.errors.answer}
                    </div>
                  </div>
                )}
              </>
            ) : this.state.data.response_type === "4" ? (
              <div class="form-group">
                <label>Answer</label>
                <div class="radio-list mb-3">
                  <label class="radio">
                    <input
                      key="true"
                      type="radio"
                      value="true"
                      onChange={(e) => {
                        const { value, checked } = e.target;
                        if (checked) {
                          let answer = [];
                          const data = this.state.data;
                          answer.push(value);
                          data.answer = answer;
                          this.setState({ data });
                        } else {
                          const data = this.state.data;
                          data.answer = [];
                          this.setState({ data });
                        }
                      }}
                      checked={
                        this.state.data.answer &&
                        this.state.data.answer.length > 0 &&
                        this.state.data.answer.find((ans) => ans === "true")
                          ? true
                          : false
                      }
                      name="true"
                    />
                    <span></span>
                    True
                  </label>
                </div>
                <div class="radio-list">
                  <label class="radio">
                    <input
                      key="false"
                      type="radio"
                      value="false"
                      onChange={(e) => {
                        const { value, checked } = e.target;
                        if (checked) {
                          let answer = [];
                          const data = this.state.data;
                          answer.push(value);
                          data.answer = answer;
                          this.setState({ data });
                        } else {
                          const data = this.state.data;
                          data.answer = [];
                          this.setState({ data });
                        }
                      }}
                      checked={
                        this.state.data.answer &&
                        this.state.data.answer.length > 0 &&
                        this.state.data.answer.find((ans) => ans === "false")
                          ? true
                          : false
                      }
                      name="false"
                    />
                    <span></span>
                    False
                  </label>
                </div>
                {this.state.errors.answer && (
                  <div className="fv-plugins-message-container">
                    <div data-validator="notEmpty" className="fv-help-block">
                      {this.state.errors.answer}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <a href="#" className="font-weight-bold"></a>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-sm font-weight-bolder"
                disabled={this.state.disable}
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  detail: getAssessment(state).detail,
});
const mapDispatchToProps = (dispatch) => ({
  loadDetailAssessment: (id) => dispatch(loadDetailAssessment(id)),
  uploadQuestionImage: (data, callback) =>
    dispatch(uploadQuestionImage(data, callback)),
  addQuestion: (id, data, callback) =>
    dispatch(addQuestion(id, data, callback)),
  updateQuestion: (id, data, callback) =>
    dispatch(updateQuestion(id, data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);
