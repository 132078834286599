import React, { Component } from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import {
  loadAllAssessment,
  addTrainings,
  uploadTrainingImage,
} from "../../store/assessment";

import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";

import _ from "lodash";

const initialState = {
  loading: false,
  browserLabel: "Browse",
  imageName: "",
  data: {
    title: "",
    description: "",
    locale: ""
  },
  images: {},
  isMandatory: false,
  isPublished: false,
  disable: false,
  errors: {},
  orgData: {},
};
const langList = [  {
  name: "English",
  code: "en"
},
{
  name: "German",
  code: "de"
},
{
  name: "French",
  code: "fr"
}
];

class AddAssessment extends Form {
  state = initialState;
  schema = {
    title: Joi.string()
      .required()
      .label("Title")
      .error(() => {
        return { message: "Title is required" };
      }),
    description: Joi.string()
      .required()
      .label("Description")
      .error(() => {
        return { message: "Description is required" };
      }),

    locale: Joi.string()
      .required()
      .label("Locale")
      .error(() => {
        return { message: "Locale is required" };
      }),
  };

  doSubmit = () => {
    const { title, description, locale} = this.state.data;
    const { errors, images } = this.state;
    if (_.isEmpty(images)) {
      errors.images = "Cover photo is required";
    } else {
      delete errors.images;
    }
    if (!_.isEmpty(errors)) {
      return this.setState({ errors });
    }
    var formdata;

    formdata = {
      name: title,
      description,
      locale,
      displayPicture: this.state.images,
      isMendatory: this.state.isMandatory,
      // isPublished: this.state.isPublished,
      isExternalTraining: this.state.isExternalTraining,
    };
    this.props.addTrainings(formdata, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAssessment({
        page: this.props.initialPage,
      });
      this.props.toggleAddUser();
      // toast(<AlertSuccess message={"Information saved"} />);
      this.setState(initialState);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  toggleClose = () => {
    this.setState(initialState);
    this.props.toggleAddUser();
  };

  uploadFile = async (e) => {
    if (e.target.files[0].size / 1024 / 1024 > 0.5) {
      toast(
        <AlertError message={"Image file size should not exceed 0.5 mb"} />
      );
    } else {
      this.setState({
        loading: true,
        browserLabel: "Uploading...",
        disable: true,
      });
      this.setState({ imageName: e.target.files[0].name });
      const file = e.target.files[0];
      const data = new FormData();
      data.append("image", file);

      this.props.uploadTrainingImage(data, (res) => {
        const errors = { ...this.state.errors };
        this.setState({ images: res.data.data });
        delete errors.images;
        this.setState({
          loading: false,
          errors,
          browserLabel: "Browse",
          disable: false,
        });
      });
    }
  };

  componentDidMount = () => {
    const data = JSON.parse(localStorage.getItem("organizationData"));
    this.setState({ orgData: data });
  };

  render() {
    const orgData = this.state.orgData;
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas3 offcanvas-left p-10 offcanvas-on "
              : "offcanvas offcanvas3 offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Add Training</h3>
            <a
              href="javascript:void (0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.toggleClose}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderSelect('locale', 'Select Lang', langList)}
            {this.renderInput("title", "Title")}
            {this.renderTextarea("description", "Description")}
            <div className="form-group">
              <label
                className={this.state.errors.photo ? "errorColor" : ""}
                htmlFor="photograph2"
              >
                Upload cover photo (Image file size should not exceed 0.5 mb)
              </label>
              <input
                accept="image/*"
                type="file"
                id="photograph2"
                className="form-control "
                onChange={this.uploadFile}
                disabled={this.state.disable}
              />

              {this.state.errors.images !== "" && (
                <div
                  className="mt-2"
                  style={{
                    color: "#f64e60",
                    fontSize: "0.9rem",
                    fontWeight: "400",
                  }}
                >
                  <div>{this.state.errors.images}</div>
                </div>
              )}
            </div>
            {!_.isEmpty(this.state.images) && (
              <div
                className="col-lg-3"
                style={{
                  padding: 0,
                  marginBottom: "10px",
                  position: "relative",
                }}
              >
                <img
                  className="img-fluid"
                  src={
                    !_.isEmpty(orgData) &&
                    orgData.mediaServerURL + this.state.images.original
                  }
                  alt=""
                />
                <a
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                  }}
                  href="javascript:void (0)"
                  className="btn btn-xs btn-icon btn-light btn-hover-primary"
                  onClick={() => {
                    this.setState({ images: {} });
                  }}
                >
                  <i className="ki ki-close icon-xs text-muted"></i>
                </a>
              </div>
            )}
            <div className="form-group d-flex align-items-center mt-3">
              <div className="checkbox-list">
                <label className="checkbox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="agree"
                    value=""
                    checked={this.state.isMandatory}
                    onClick={(e) => {
                      const { checked } = e.target;
                      if (checked) {
                        this.setState({ isMandatory: true });
                      } else {
                        this.setState({ isMandatory: false });
                      }
                    }}
                  />
                  <span></span>
                </label>
              </div>
              <label className="mb-0">Mandatory</label>
            </div>
            {/* <div className="form-group d-flex align-items-center mt-3">
              <div className="checkbox-list">
                <label className="checkbox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="agree"
                    value=""
                    checked={this.state.isPublished}
                    onClick={(e) => {
                      const { checked } = e.target;
                      if (checked) {
                        this.setState({ isPublished: true });
                      } else {
                        this.setState({ isPublished: false });
                      }
                    }}
                  />
                  <span></span>
                </label>
              </div>
              <label className="mb-0">Publish</label>
            </div> */}
            <div className="form-group d-flex align-items-center mt-3">
              <div className="checkbox-list">
                <label className="checkbox">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="agree"
                    value=""
                    checked={this.state.isExternalTraining}
                    onClick={(e) => {
                      const { checked } = e.target;
                      if (checked) {
                        this.setState({ isExternalTraining: true });
                      } else {
                        this.setState({ isExternalTraining: false });
                      }
                    }}
                  />
                  <span></span>
                </label>
              </div>
              <label className="mb-0">External Training</label>
            </div>
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <a href="#" className="font-weight-bold"></a>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-sm font-weight-bolder"
                disabled={this.state.disable}
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  loadAllAssessment: (param) => dispatch(loadAllAssessment(param)),
  addTrainings: (data, callback) => dispatch(addTrainings(data, callback)),
  uploadTrainingImage: (data, callback) =>
    dispatch(uploadTrainingImage(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddAssessment);
