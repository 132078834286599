import React, { Component } from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";

import _ from "lodash";
import {
  addProduct,
  loadAllProduct,
  updateProduct,
  uploadProductImage,
} from "../../store/shop";

const initialState = {
  loading: false,
  browserLabel: "Browse",
  imageName: "",
  data: {
    name: "",
    description: "",
    stars: "",
  },
  images: {},
  disable: false,
  errors: {},
  orgData: {},
};
class AddAssessment extends Form {
  state = initialState;
  schema = {
    name: Joi.string()
      .required()
      .label("Name")
      .error(() => {
        return { message: "Name is required" };
      }),
    description: Joi.string()
      .required()
      .label("Description")
      .error(() => {
        return { message: "Description is required" };
      }),
    stars: Joi.number()
      .required()
      .label("Stars")
      .error(() => {
        return { message: "Stars is required" };
      }),
  };

  doSubmit = () => {
    const { name, stars, description } = this.state.data;
    var formdata;

    formdata = {
      name,
      description,
      displayPicture: this.state.images,
      stars,
    };
    if (this.props.editing) {
      this.props.updateProduct(
        this.props.editData._id,
        formdata,
        this.callback
      );
    } else {
      this.props.addProduct(formdata, this.callback);
    }
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllProduct({
        sort: "createdAt",
        order: "desc",
        page: this.props.initialPage,
      });
      this.toggleClose();
      // toast(<AlertSuccess message={"Information saved"} />);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  toggleClose = () => {
    this.setState(
      {
        loading: false,
        browserLabel: "Browse",
        imageName: "",
        data: {
          name: "",
          description: "",
          stars: "",
        },
        images: {},
        disable: false,
        errors: {},
      },
      () => {
        this.props.toggleAddShop();
        this.props.setEdting(false);
        this.props.setEditData(null);
      }
    );
  };

  uploadFile = async (e) => {
    if (e.target.files[0].size / 1024 / 1024 > 25) {
      toast(<AlertError message={"Image file size should not exceed 25 mb"} />);
    } else {
      this.setState({
        loading: true,
        browserLabel: "Uploading...",
        disable: true,
      });
      this.setState({ imageName: e.target.files[0].name });
      const file = e.target.files[0];
      const data = new FormData();
      data.append("image", file);

      this.props.uploadProductImage(data, (res) => {
        const errors = { ...this.state.errors };
        this.setState({ images: res.data.data });
        delete errors.images;
        this.setState({
          loading: false,
          errors,
          browserLabel: "Browse",
          disable: false,
        });
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.editing &&
      this.props.editData !== null &&
      this.props.editData !== prevProps.editData
    ) {
      const data = this.state.data;
      data.name = this.props.editData.name;
      data.description = this.props.editData.description;
      data.stars = this.props.editData.stars.toString();
      this.setState({ data, images: this.props.editData.displayPicture });
    }
  };

  componentDidMount = () => {
    const data = JSON.parse(localStorage.getItem("organizationData"));
    this.setState({ orgData: data });
  };

  render() {
    const orgData = this.state.orgData;
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "offcanvas offcanvas3 offcanvas-left p-10 offcanvas-on "
              : "offcanvas offcanvas3 offcanvas-left p-10"
          }
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">
              {this.props.editing ? "Edit Product" : "Add Product"}
            </h3>
            <a
              href="javascript:void (0)"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              onClick={this.toggleClose}
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            {this.renderInput("name", "Name")}
            {this.renderTextarea("description", "Description")}
            {this.renderInput("stars", "Stars", "number")}
            <div className="form-group">
              <label
                className={this.state.errors.photo ? "errorColor" : ""}
                htmlFor="photograph2"
              >
                Upload product photo (Image file size should not exceed 25 mb)
              </label>
              <input
                accept="image/*"
                type="file"
                id="photograph2"
                className="form-control "
                onChange={this.uploadFile}
                disabled={this.state.disable}
              />

              {this.state.errors.image && (
                <div className="fv-plugins-message-container">
                  <div data-validator="notEmpty" className="fv-help-block">
                    {this.state.errors.image}
                  </div>
                </div>
              )}
            </div>
            {!_.isEmpty(this.state.images) && (
              <div
                className="col-lg-3"
                style={{
                  padding: 0,
                  marginBottom: "10px",
                  position: "relative",
                }}
              >
                <img
                  className="img-fluid"
                  src={
                    !_.isEmpty(orgData) &&
                    orgData.mediaServerURL + this.state.images.original
                  }
                  alt=""
                />
                <a
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                  }}
                  href="javascript:void (0)"
                  className="btn btn-xs btn-icon btn-light btn-hover-primary"
                  onClick={() => {
                    this.setState({ images: {} });
                  }}
                >
                  <i className="ki ki-close icon-xs text-muted"></i>
                </a>
              </div>
            )}

            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <a href="#" className="font-weight-bold"></a>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-sm font-weight-bolder"
                disabled={this.state.disable}
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  loadAllProduct: (param) => dispatch(loadAllProduct(param)),
  addProduct: (data, callback) => dispatch(addProduct(data, callback)),
  updateProduct: (id, data, callback) =>
    dispatch(updateProduct(id, data, callback)),
  uploadProductImage: (data, callback) =>
    dispatch(uploadProductImage(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddAssessment);
