import React from "react";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import logo from "../../include/media/logo.svg";
import coverImage from "../../include/media/coverImage.jpg";

import Form from "../common/form/form";
import { adminSignIn } from "../../store/admin";
import AlertError from "../common/alertError";
import { toast } from "react-toastify";

// xiYEV392%hjR

import { externalCode } from "../common/misc";
import axios from "axios";

class SignIn extends Form {
  state = {
    details: [],
    data: {
      code: "",
    },
    errors: {
      code: "",
    },
  };

  schema = {
    code: Joi.string()
      .required()
      .label("Email")
      .error(() => {
        return { message: externalCode };
      }),
  };

  doSubmit = () => {
    const { data } = this.state;
    const headers = {
      Authorization: process.env.REACT_APP_TOKEN,
    };
    axios
      .post(
        process.env.REACT_APP_APIBASE + `auth/siteConfiguration`,
        {
          siteId: data.code,
        },
        { headers }
      )
      .then((res) => {
        const data = res.data.data;
        typeof window !== "undefined" &&
          localStorage.setItem("organizationData", JSON.stringify(data));

        window.location.href = "/signin";
      })
      .catch((error) => {
        console.log(error);
        return toast(<AlertError message={"Organisation code is invalid"} />);
      });
  };

  render() {
    return (
      <div
        className="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        id="kt_login"
        style={{ height: "100vh" }}
      >
        <div className="login-aside order-2 order-lg-1 d-flex flex-column-fluid flex-lg-row-auto bgi-size-cover bgi-no-repeat p-7 p-lg-10">
          <div className="d-flex flex-row-fluid flex-column justify-content-between">
            <div className="d-flex flex-column-fluid flex-column flex-center mt-5 mt-lg-0">
              <Link tp={"#"} className="mb-15 text-center">
                <img src={logo} className="max-h-75px" alt="" />
              </Link>
              <div className="login-form login-signin">
                <div className="text-center mb-10 mb-lg-20">
                  {/* <h2 className="font-weight-bold">Sign In</h2>
                  <p className="font-weight-bold" style={{ color: "#777" }}>
                    Enter your email and password
                  </p> */}
                </div>
                <form action="" onSubmit={this.handleSubmit}>
                  {this.renderInput("code", "Enter Organisation Code")}

                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-3">
                    <div className="checkbox-inline"></div>
                  </div>
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                    <div className="my-3 mr-2">
                      <span className="text-muted mr-2"></span>
                      <Link to={"#"} className="font-weight-bold"></Link>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm font-weight-bolder"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className="order-1 order-lg-2 external-page-image flex-column-auto flex-lg-row-fluid d-flex align-items-center p-7 layout-overlay"
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3)), url(${coverImage})`,
            backgroundPosition: "top",
            backgroundSize: "cover",
          }}
        >
          <div className="d-flex flex-column login-right-content">
            <h3 className="display-3 company-name-font font-weight-bold my-7 text-white">
              {" "}
              Welcome to Gamtred!
            </h3>

            <p
              className="font-weight-bold text-white signin"
              style={{ opacity: 0.9, fontSize: "18px" }}
            >
              Design engaging game-based sessions In minutes, track learner
              progress, manage content and rewards.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  adminSignIn: (param, callback) => dispatch(adminSignIn(param, callback)),
});
export default withRouter(connect(null, mapDispatchToProps)(SignIn));
